/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
/* eslint-disable comma-dangle */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* react/jsx-closing-tag-location */

import React from "react";
import { Fade } from "react-awesome-reveal";
import * as emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "elements/Button";

export default function DiscussForm(props) {
  const { data, resetForm, onChange } = props;

  const submitEmail = () => {
    const {
      name,
      company,
      email,
      phone,
      projectType,
      projectIdea,
      additionalInfo,
      budget,
      softwareType,
      contentType,
    } = data;

    const templateParams = {
      from_name: `${name} - ${company} ( ${phone} - ${email} )`,
      to_name: "TTP TECH",
      projectType: projectType,
      message: projectIdea,
      contentType: contentType || "N/A", // Default to 'N/A' if not applicable
      softwareType: softwareType || "N/A", // Default to 'N/A' if not applicable
      additionalInfo: additionalInfo,
      budget: budget,
    };

    if (
      name !== "" &&
      company !== "" &&
      email !== "" &&
      phone !== "" &&
      projectType !== "" &&
      projectIdea !== "" &&
      budget !== ""
    ) {
      emailjs
        .send(
          "service_m9ez6le",
          "template_8b22j2g",
          templateParams,
          "user_Jmss4bRHKILhJ0KTp7Zxf"
        )
        .then(
          () => {
            toast.success("Success! We'll get back to you soon. Thank you!");
            resetForm();
          },
          (error) => {
            toast.error(error);
          }
        );
    } else {
      toast.error("Please fill out the blank form.");
    }
  };

  const budgetOptions = () => {
    switch (data.projectType) {
      case "Software Development":
        return (
          <div className="mx-auto my-4 text-theme-blue">
            <label className="block font-bold text-lg md:text-xl mb-4 text-theme-purple bg-gray-100 p-3 rounded-lg border-2 border-theme-purple shadow-lg">
              Select your budget
            </label>
            <div className="flex flex-col sm:flex-row gap-4">
              <label>
                <input
                  type="radio"
                  name="budget"
                  value="$5,000 - $10,000"
                  checked={data.budget === "$5,000 - $10,000"}
                  onChange={onChange}
                />{" "}
                $5,000 - $10,000
              </label>
              <label>
                <input
                  type="radio"
                  name="budget"
                  value="$15,000 - $25,000"
                  checked={data.budget === "$15,000 - $25,000"}
                  onChange={onChange}
                />{" "}
                $15,000 - $25,000
              </label>
              <label>
                <input
                  type="radio"
                  name="budget"
                  value="$35,000 - $45,000"
                  checked={data.budget === "$35,000 - $45,000"}
                  onChange={onChange}
                />{" "}
                $35,000 - $45,000
              </label>
              <label>
                <input
                  type="radio"
                  name="budget"
                  value="Above $55,000"
                  checked={data.budget === "Above $55,000"}
                  onChange={onChange}
                />{" "}
                Above $55,000
              </label>
            </div>
          </div>
        );
      case "Content Creation":
        return (
          <div className="mx-auto my-4 text-theme-blue">
            <label className="block font-bold text-lg md:text-xl mb-4 text-theme-purple bg-gray-100 p-3 rounded-lg border-2 border-theme-purple shadow-lg">
              Select your budget
            </label>
            <div className="flex flex-col sm:flex-row gap-4">
              <label>
                <input
                  type="radio"
                  name="budget"
                  value="Under $2,000"
                  checked={data.budget === "Under $2,000"}
                  onChange={onChange}
                />{" "}
                Under $2,000
              </label>
              <label>
                <input
                  type="radio"
                  name="budget"
                  value="$2,000 - $5,000"
                  checked={data.budget === "$2,000 - $5,000"}
                  onChange={onChange}
                />{" "}
                $2,000 - $5,000
              </label>
              <label>
                <input
                  type="radio"
                  name="budget"
                  value="$5,000 - $10,000"
                  checked={data.budget === "$5,000 - $10,000"}
                  onChange={onChange}
                />{" "}
                $5,000 - $10,000
              </label>
              <label>
                <input
                  type="radio"
                  name="budget"
                  value="Above $10,000"
                  checked={data.budget === "Above $10,000"}
                  onChange={onChange}
                />{" "}
                Above $10,000
              </label>
            </div>
          </div>
        );
      case "All":
        return (
          <div className="mx-auto my-4">
            <label className="block font-bold text-lg md:text-xl mb-4 text-theme-purple bg-gray-100 p-3 rounded-lg border-2 border-theme-purple shadow-lg">
              Select your budget
            </label>
            <div className="flex flex-col sm:flex-row gap-4">
              <label>
                <input
                  type="radio"
                  name="budget"
                  value="Under $7,000"
                  checked={data.budget === "Under $7,000"}
                  onChange={onChange}
                />{" "}
                Under $7,000
              </label>
              <label>
                <input
                  type="radio"
                  name="budget"
                  value="$7,000 - $15,000"
                  checked={data.budget === "$7,000 - $15,000"}
                  onChange={onChange}
                />{" "}
                $7,000 - $15,000
              </label>
              <label>
                <input
                  type="radio"
                  name="budget"
                  value="$15,000 - $30,000"
                  checked={data.budget === "$15,000 - $30,000"}
                  onChange={onChange}
                />{" "}
                $15,000 - $30,000
              </label>
              <label>
                <input
                  type="radio"
                  name="budget"
                  value="Above $30,000"
                  checked={data.budget === "Above $30,000"}
                  onChange={onChange}
                />{" "}
                Above $30,000
              </label>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <section className="flex flex-col container mx-auto mt-10 justify-center">
      <Fade bottom>
        <h1 className="text-5xl text-theme-blue text-center font-bold">
          Let's Start
        </h1>

        <p className="font-light text-lg text-gray-400 text-center mb-12">
          Please fill out the form below to discuss your project and we'll get
          back to you ASAP.
        </p>

        <div className="flex flex-col">
          <div className="">
            <input
              id="name"
              name="name"
              type="text"
              value={data.name}
              placeholder="Your name"
              className="p-4 font-light text-lg text-theme-blue rounded border border-gray-400 focus:outline-none focus:ring-1 focus:ring-theme-purple"
              onChange={onChange}
            />
            <input
              id="company"
              name="company"
              type="text"
              value={data.company}
              placeholder="Your company"
              className="p-4 font-light text-lg text-theme-blue rounded border border-gray-400 focus:outline-none focus:ring-1 focus:ring-theme-purple"
              onChange={onChange}
            />
          </div>

          <div className="">
            <input
              id="email"
              name="email"
              type="email"
              value={data.email}
              placeholder="Your email address"
              className="p-4 font-light text-lg text-theme-blue rounded border border-gray-400 focus:outline-none focus:ring-1 focus:ring-theme-purple"
              onChange={onChange}
            />
            <input
              id="phone"
              name="phone"
              type="tel"
              value={data.phone}
              placeholder="Your contact number"
              className="p-4 font-light text-lg text-theme-blue rounded border border-gray-400 focus:outline-none focus:ring-1 focus:ring-theme-purple"
              onChange={onChange}
            />
          </div>

          <div className="mx-auto my-4 text-theme-blue">
            <label className="block font-bold text-lg md:text-xl mb-4 text-theme-purple bg-gray-100 p-3 rounded-lg border-2 border-theme-purple shadow-lg">
              What type of project?
            </label>
            <div className="flex flex-col sm:flex-row gap-4">
              <label>
                <input
                  type="radio"
                  name="projectType"
                  value="Software Development"
                  checked={data.projectType === "Software Development"}
                  onChange={onChange}
                />{" "}
                Software Development
              </label>
              <label>
                <input
                  type="radio"
                  name="projectType"
                  value="Content Creation"
                  checked={data.projectType === "Content Creation"}
                  onChange={onChange}
                />{" "}
                Content Creation
              </label>
              <label>
                <input
                  type="radio"
                  name="projectType"
                  value="All"
                  checked={data.projectType === "All"}
                  onChange={onChange}
                />{" "}
                All
              </label>
              <label>
                <input
                  type="radio"
                  name="projectType"
                  value="Monthly Maintenance Plan"
                  checked={data.projectType === "Monthly Maintenance Plan"}
                  onChange={onChange}
                />{" "}
                Monthly Maintenance Plan
              </label>
            </div>
          </div>

          {data.projectType === "Software Development" && (
            <div className="mx-auto my-4 text-theme-blue">
              <label className="block font-bold text-lg md:text-xl mb-4 text-theme-purple bg-gray-100 p-3 rounded-lg border-2 border-theme-purple shadow-lg">
                What type of software project?
              </label>
              <div className="flex flex-col sm:flex-row gap-4">
                <label>
                  <input
                    type="radio"
                    name="softwareType"
                    value="Mobile App"
                    checked={data.softwareType === "Mobile App"}
                    onChange={onChange}
                  />{" "}
                  Mobile App
                </label>
                <label>
                  <input
                    type="radio"
                    name="softwareType"
                    value="Website"
                    checked={data.softwareType === "Website"}
                    onChange={onChange}
                  />{" "}
                  Website
                </label>
              </div>
            </div>
          )}

          {data.projectType === "Content Creation" && (
            <div className="mx-auto my-4 text-theme-blue">
              <label className="block font-bold text-lg md:text-xl mb-4 text-theme-purple bg-gray-100 p-3 rounded-lg border-2 border-theme-purple shadow-lg">
                What type of content creation project?
              </label>
              <div className="flex flex-col sm:flex-row gap-4">
                <label>
                  <input
                    type="radio"
                    name="contentType"
                    value="Video Production"
                    checked={data.contentType === "Video Production"}
                    onChange={onChange}
                  />{" "}
                  Video Production
                </label>
                <label>
                  <input
                    type="radio"
                    name="contentType"
                    value="Graphic Design"
                    checked={data.contentType === "Graphic Design"}
                    onChange={onChange}
                  />{" "}
                  Graphic Design
                </label>
              </div>
            </div>
          )}

          {budgetOptions()}

          <div className="mx-auto textarea">
            <textarea
              id="projectIdea"
              name="projectIdea"
              value={data.projectIdea}
              placeholder="Briefly describe your project idea"
              className="w-95 sm:w-192 lg:w-192.5 xl:w-192.5 h-150 p-4 mx-2 mb-6 font-light text-lg text-theme-blue rounded border border-gray-400 focus:outline-none focus:ring-1 focus:ring-theme-purple"
              onChange={onChange}
            />
          </div>

          <div className="mx-auto textarea">
            <textarea
              id="additionalInfo"
              name="additionalInfo"
              value={data.additionalInfo}
              placeholder="Any additional information (optional)"
              className="w-95 sm:w-192 lg:w-192.5 xl:w-192.5 h-100 p-4 mx-2 mb-6 font-light text-lg text-theme-blue rounded border border-gray-400 focus:outline-none focus:ring-1 focus:ring-theme-purple"
              onChange={onChange}
            />
          </div>

          <Button
            className="flex items-center justify-center text-xl mx-auto px-12 py-3 mt-5 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200 focus:outline-none"
            type="button"
            onClick={submitEmail}
          >
            Submit
            <svg
              className="ml-1 w-5 h-5 animate-bounce-x"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </Button>
        </div>
      </Fade>

      <ToastContainer />
    </section>
  );
}
