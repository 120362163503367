/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'assets/css/styles.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import App from './App';
import reportWebVitals from './reportWebVitals';
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAbo-WXjKbNNfkEviZp3kYfmeGnr-CNLdc",
  authDomain: "ttptechllc-5b637.firebaseapp.com",
  projectId: "ttptechllc-5b637",
  storageBucket: "ttptechllc-5b637.appspot.com",
  messagingSenderId: "642907208039",
  appId: "1:642907208039:web:a6ad37441254a2d4c157c2",
  measurementId: "G-3VVQZF36MV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
