import React, { useState } from "react";
import { db, collection, addDoc } from "../firebase-config";

const NewsletterForm = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(collection(db, "subscribers"), {
        email,
        subscribedAt: new Date(),
      });
      alert("Thank you for subscribing!");
      setEmail("");
    } catch (error) {
      console.error("Error adding subscriber: ", error);
    }
  };

  return (
    <form
      className="flex flex-col w-10/12 sm:w-11/12 xl:w-10/12 rounded-2xl bg-theme-purple text-gray-100 py-8 sm:px-5 sm:py-8 xl:px-5 shadow-2xl"
      onSubmit={handleSubmit}
      style={{
        gap: "15px", // Adds space between form elements
      }}
    >
      <input
        type="email"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        className="w-full p-2 border rounded"
        style={{
          padding: "10px 15px",
          width: "100%",
          borderRadius: "4px",
          border: "1px solid #fff", // Adjusts border to make the input more defined
          background: "rgba(255, 255, 255, 0.1)", // Slightly lighter background for the input
          color: "inherit", // Ensures text color matches the modal
          outline: "none", // Removes the default focus outline
          placeholderTextColor: "rgba(255, 255, 255, 0.7)", // Ensures placeholder text is visible
        }}
        placeholder="Enter your email"
      />
      <button
        type="submit"
        style={{
          padding: "10px 20px",
          paddingLeft: "30px",
          borderRadius: "4px",
          border: "none",
          background: "rgba(255, 255, 255, 0.2)", // Subtle background for the button
          color: "inherit", // Inherits the text color
          cursor: "pointer",
          transition: "background-color 0.3s", // Smooth background color transition on hover
        }}
        onMouseOver={(e) =>
          (e.currentTarget.style.backgroundColor = "rgba(255, 255, 255, 0.3)")
        } // Slightly changes background color on hover
        onMouseOut={(e) =>
          (e.currentTarget.style.backgroundColor = "rgba(255, 255, 255, 0.2)")
        } // Reverts background color on mouse out
      >
        Subscribe
      </button>
    </form>
  );
};

export default NewsletterForm;
