/* eslint-disable react/jsx-filename-extension */
import React from "react";

import { Fade } from "react-awesome-reveal";
// import Team from 'assets/images/about.png';

export default function HeroTeam() {
  return (
    <section className="hero sm:items-center lg:items-start sm:flex-row">
      <Fade bottom>
        <div>
          <p className="font-light text-xl text-gray-400 leading-relaxed">
            My entrepreneurial journey began with teaching myself to code,
            launching a tech company, and building a sauce business that honors
            my Aunt's legacy. Balancing these efforts with earning a full-ride
            academic scholarship while competing in track and field taught me
            the discipline and resilience needed to succeed.
          </p>
          <p className="text-white">.</p>
          <p className="font-light text-xl text-gray-400 leading-relaxed">
            But as I grew, I knew that real growth and impact required more than
            just personal effort. That's why I've built a highly skilled team
            that matches my level of expertise and drive, so we can scale this
            business and serve entrepreneurs at the highest level. Together, we
            bring diverse skills in coding, branding, marketing, and operations
            to help you break through barriers and grow with confidence.
          </p>
          <p className="text-white">.</p>
          <p className="font-light text-xl text-gray-400 leading-relaxed">
            Whether you're feeling stuck at a plateau or need fresh strategies
            to scale your business, we provide the clarity and systems you need
            to thrive. Our goal is to empower entrepreneurs like you to excel by
            sharing the same principles that have helped me launch and grow
            multiple businesses.
          </p>
          <p className="text-white">.</p>
          <p className="font-light text-xl text-gray-400 leading-relaxed">
            If you're ready to reach new heights and unlock your business's full
            potential, let's connect and make it happen.
          </p>
        </div>
        {/* <div className="w-full sm:w-1/2 sm:pr-12">
          <img src={Team} alt="Hero" />
        </div> */}
      </Fade>
    </section>
  );
}
