import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAbo-WXjKbNNfkEviZp3kYfmeGnr-CNLdc",
    authDomain: "ttptechllc-5b637.firebaseapp.com",
    projectId: "ttptechllc-5b637",
    storageBucket: "ttptechllc-5b637.appspot.com",
    messagingSenderId: "642907208039",
    appId: "1:642907208039:web:a6ad37441254a2d4c157c2",
    measurementId: "G-3VVQZF36MV",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  // Initialize Firestore
  const db = getFirestore(app);

  export { db, collection, addDoc };
