/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
/* eslint-disable comma-dangle */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from "react";
import emailjs from "emailjs-com";
import { jsPDF } from "jspdf";
import Header from "parts/Header"; // Assuming your Header component is here
import Footer from "parts/Footer"; // Assuming your Footer component is here
import "../assets/css/InvoiceGeneratorPage.css"; // Add your custom styling

export default class InvoiceGeneratorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessName: "",
      businessAddress: "",
      businessContact: "",
      clientName: "",
      email: "",
      invoiceNumber: "",
      date: "",
      dueDate: "",
      items: [{ description: "", quantity: 1, price: 0 }],
      totalAmount: 0,
    };
  }

  // Handle input change for form fields
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  // Handle line item change
  handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const items = [...this.state.items];
    items[index][name] = value;
    this.setState({ items }, this.calculateTotal);
  };

  // Add a new item row
  addItem = () => {
    this.setState({
      items: [...this.state.items, { description: "", quantity: 1, price: 0 }],
    });
  };

  // Remove an item row
  removeItem = (index) => {
    const items = [...this.state.items];
    items.splice(index, 1);
    this.setState({ items }, this.calculateTotal);
  };

  // Calculate the total amount
  calculateTotal = () => {
    const total = this.state.items.reduce((sum, item) => {
      return sum + item.quantity * item.price;
    }, 0);
    this.setState({ totalAmount: total.toFixed(2) });
  };

  // Validate email format
  validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  // Send invoice details via EmailJS
  sendInvoice = () => {
    const {
      businessName,
      businessAddress,
      businessContact,
      clientName,
      email,
      invoiceNumber,
      date,
      dueDate,
      items,
      totalAmount,
    } = this.state;

    emailjs
      .send(
        "service_m9ez6le", // Your EmailJS service ID
        "template_z0voz9g", // Your EmailJS template ID
        {
          businessName: businessName,
          businessAddress: businessAddress,
          businessContact: businessContact,
          clientName: clientName,
          email: email,
          invoiceNumber: invoiceNumber,
          date: date,
          dueDate: dueDate,
          items: items
            .map(
              (item) =>
                `${item.description} (${item.quantity} x $${item.price})`
            )
            .join(", "),
          totalAmount: totalAmount,
        },
        "user_Jmss4bRHKILhJ0KTp7Zxf" // Your EmailJS public key/user ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  // Generate and download the PDF
  generatePDF = () => {
    const {
      businessName,
      businessAddress,
      businessContact,
      clientName,
      invoiceNumber,
      date,
      dueDate,
      items,
      totalAmount,
    } = this.state;
    const doc = new jsPDF();

    // Set up title and business/client information
    doc.setFontSize(18);
    doc.text(`${businessName} Invoice`, 10, 10);
    doc.setFontSize(12);
    doc.text(`Invoice Number: ${invoiceNumber}`, 10, 30);
    doc.text(`Business Address: ${businessAddress}`, 10, 40);
    doc.text(`Business Contact: ${businessContact}`, 10, 50);
    doc.text(`Client Name: ${clientName}`, 10, 60);
    doc.text(`Invoice Date: ${date}`, 10, 70);
    doc.text(`Invoice Due Date: ${dueDate}`, 10, 80);

    // Table Setup
    let startX = 10;
    let startY = 100; // Ensure space below the header
    let lineHeight = 10;
    let padding = 5; // Padding for Quantity and Price columns
    let maxDescriptionWidth = 80; // Max width for description text
    let pageHeight = doc.internal.pageSize.height;
    let rowPosition = startY + lineHeight;

    // Table Headers
    doc.setFontSize(12);
    doc.text("Description", startX + 2, startY + 5);
    doc.text("Quantity", startX + 90 + padding, startY + 5);
    doc.text("Price", startX + 140 + padding, startY + 5);

    // Draw header rectangle
    doc.rect(startX, startY, 190, lineHeight); // Box around headers

    // Move rowPosition down to avoid overlap with first item
    rowPosition += lineHeight;

    // Table Rows for Items
    items.forEach((item) => {
      // Split description into multiple lines if needed
      let descriptionLines = doc.splitTextToSize(
        item.description,
        maxDescriptionWidth
      );
      let numberOfLines = descriptionLines.length;
      let rowHeight = lineHeight * numberOfLines;

      // Check if current row position exceeds page height
      if (rowPosition + rowHeight > pageHeight - 20) {
        doc.addPage();
        rowPosition = 20; // Reset position

        // Redraw the table headers on new page
        doc.text("Description", startX + 2, rowPosition + 5);
        doc.text("Quantity", startX + 90 + padding, rowPosition + 5);
        doc.text("Price", startX + 140 + padding, rowPosition + 5);
        doc.rect(startX, rowPosition, 190, lineHeight); // Header rectangle on new page
        rowPosition += lineHeight;
      }

      // Add description, quantity, and price
      descriptionLines.forEach((line, index) => {
        doc.text(line, startX + 2, rowPosition + index * lineHeight - 3);
      });

      let quantityY = rowPosition + (numberOfLines - 1) * lineHeight - 3;
      let priceY = rowPosition + (numberOfLines - 1) * lineHeight - 3;

      doc.text(`${item.quantity}`, startX + 90 + padding, quantityY);
      doc.text(`$${item.price}`, startX + 140 + padding, priceY);

      // Draw box around the item row
      // doc.rect(startX, rowPosition, 190, rowHeight); // Rectangle for item

      // Move to next row
      rowPosition += rowHeight;
    });

    // Draw final total
    rowPosition += lineHeight;
    doc.text(`Total: $${totalAmount}`, 150 + padding, rowPosition + 10);

    // Save PDF
    doc.save(`invoice_${invoiceNumber}_${clientName}.pdf`);
  };

  // Handle form submission
  handleSubmit = (e) => {
    e.preventDefault();
    const { email } = this.state;

    if (!this.validateEmail(email)) {
      alert("Please enter a valid email.");
      return;
    }

    // Check if the email is 'ttptechllc@gmail.com' and skip sending the email
    if (email === "ttptechllc@gmail.com") {
      console.log("Internal email detected, skipping EmailJS.");
    } else {
      this.sendInvoice();
    }

    // Generate the PDF regardless of the email condition
    this.generatePDF();
  };

  render() {
    const {
      businessName,
      businessAddress,
      businessContact,
      clientName,
      email,
      invoiceNumber,
      date,
      dueDate,
      items,
      totalAmount,
    } = this.state;

    return (
      <>
        <Header {...this.props} />
        <div className="invoice-generator-container">
          <h1 className="text-6xl text-theme-blue font-bold leading-tight">
            Invoice Generator
          </h1>
          <p>
            TTP Tech's invoice generator simplifies your billing process and
            helps you collect payments efficiently. Our user-friendly tool
            provides a professional template that includes all the essential
            details you need to invoice your clients. Simply fill in the
            necessary information, and our generator will create a polished
            invoice that download directly to your device.
          </p>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label>Business Name</label>
              <input
                type="text"
                name="businessName"
                value={businessName}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Business Address</label>
              <input
                type="text"
                name="businessAddress"
                value={businessAddress}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Business Contact Email</label>
              <input
                type="email"
                name="businessContact"
                value={businessContact}
                onChange={this.handleChange}
                required
              />
              <footer style={{ fontSize: "12px" }}>
                By entering your email, you agree to receive marketing emails
                from TTP TECH LLC.
              </footer>
            </div>

            <div className="form-group">
              <label>Client Name</label>
              <input
                type="text"
                name="clientName"
                value={clientName}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Invoice Number</label>
              <input
                type="text"
                name="invoiceNumber"
                value={invoiceNumber}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Date</label>
              <input
                type="date"
                name="date"
                value={date}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Due Date</label>
              <input
                type="date"
                name="dueDate"
                value={dueDate}
                onChange={this.handleChange}
                required
              />
            </div>

            {/* Line items */}
            {items.map((item, index) => (
              <div className="form-group" key={index}>
                <label>Description</label>
                <input
                  type="text"
                  name="description"
                  value={item.description}
                  onChange={(e) => this.handleItemChange(index, e)}
                  required
                />
                <label>Quantity</label>
                <input
                  type="number"
                  name="quantity"
                  value={item.quantity}
                  onChange={(e) => this.handleItemChange(index, e)}
                  min="1"
                  required
                />
                <label>Price</label>
                <input
                  type="number"
                  name="price"
                  value={item.price}
                  onChange={(e) => this.handleItemChange(index, e)}
                  min="0"
                  required
                />
                <button
                  className="removeitem-btn"
                  type="button"
                  onClick={() => this.removeItem(index)}
                >
                  Remove 🗑️
                </button>
              </div>
            ))}

            <button
              className="additem-btn"
              type="button"
              onClick={this.addItem}
            >
              Add Item
            </button>

            <div className="form-group">
              <h3>Total: ${totalAmount}</h3>
            </div>

            <button type="submit" className="submit-btn transform transition duration-500 hover:scale-105">
              Generate and Download Invoice
            </button>
          </form>
        </div>
        <Footer />
      </>
    );
  }
}
