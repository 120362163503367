import React from 'react';
import FaqItem from './FaqItem';

const faqs = [
    {
        question: 'What services do you provide?',
        answer: 'We offer a range of services including custom website development, mobile app development, user authentication, email integration, ad work, lead generation, and more.',
      },
      {
        question: 'How long does it typically take to build a website?',
        answer: 'The timeline for building a website can vary depending on its complexity, but typically it takes between 4 to 12 weeks from start to finish.',
      },
      {
        question: 'Do you offer maintenance and support after the website goes live?',
        answer: 'Yes, we provide ongoing maintenance and support services to ensure your website remains updated and operates smoothly.',
      },
      {
        question: 'Can you help with search engine optimization (SEO)?',
        answer: 'Absolutely, SEO is a crucial part of our web development process, and we work to ensure your website is optimized for search engines.',
      },
      {
        question: 'Are your websites mobile-friendly?',
        answer: 'Yes, all our websites are designed to be responsive and mobile-friendly, providing an optimal browsing experience across all devices.',
      },
      {
        question: 'Do you offer web hosting services?',
        answer: 'We do offer web hosting services, along with our development packages, to ensure high performance and reliability.',
      },
      {
        question: 'Can you integrate social media into our website?',
        answer: 'Certainly, we can integrate social media platforms into your website to enhance your online presence and connectivity with your audience.',
      },
      {
        question: 'Do you offer e-commerce solutions?',
        answer: 'Yes, we specialize in e-commerce development, providing robust and secure platforms for online sales and transactions.',
      },
      {
        question: 'Will I be able to update the website content myself?',
        answer: "Depending on the website platform, you will be able to update site. If you're looking for a custom-coded website platform, we provide maintenance plans tailored to support such bespoke solutions.",
      },
      {
        question: 'How do we get started on a project?',
        answer: 'Getting started is easy – just contact us with your project details, and we’ll set up a meeting to discuss your needs and how we can help.',
      },
      {
        question: 'Do you offer payment plans?',
        answer: 'Yes, we offer payment plans based on milestone completions to provide flexibility for our clients.',
      },
];

const FaqList = () => (
    <>
    <div className="faq-list rounded-2xl bg-theme-purple text-gray-100 py-8 sm:px-8 sm:py-8 xl:px-16 shadow-2xl discuss">
    <h1 className="text-3xl sm:text-4xl text-theme-white font-bold leading-tight mb-5"> Common Questions: FAQ</h1>

      {faqs.map((faq) => (
        <FaqItem
          key={faq.question} // Ensure each question is unique; otherwise, use a different unique identifier
          question={faq.question}
          answer={faq.answer}
        />
      ))}
    </div>
    </>
  );

export default FaqList;
