import React from 'react';

function StatsBanner() {
  const bannerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    color: 'black',
    padding: '50px 0',
    marginBottom: "150px",
  };

  const headingStyle = {
    fontSize: '30px',
    fontWeight: 'bold',
    marginBottom: '10px',
  };

  const subheadingStyle = {
    fontSize: '20px',
    marginBottom: '30px',
  };

  const statsContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  };

  const statStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const numberStyle = {
    fontSize: '50px',
    fontWeight: 'bold',
  };

  const descriptionStyle = {
    fontSize: '18px',
    textAlign: 'center',
  };

  return (
    <div style={bannerStyle}>
      <div className="text-theme-blue" style={headingStyle}>Why choose us</div>
      <div className="font-light text-gray-400" style={subheadingStyle}>
        Disrupting the market through innovative software development
      </div>
      <div style={statsContainerStyle}>
        <div style={statStyle}>
          <div className="text-theme-blue" style={numberStyle}>7+</div>
          <div className="font-light text-gray-400" style={descriptionStyle}>
            Years xperience delivering software development services.
          </div>
        </div>
        <div style={statStyle}>
          <div className="text-theme-blue" style={numberStyle}>50+</div>
          <div className="font-light text-gray-400" style={descriptionStyle}>
            Satisfied customers across the startup space.
          </div>
        </div>
        {/* <div style={statStyle}>
          <div className="text-theme-blue" style={numberStyle}>Top 20%</div>
          <div className="font-light text-gray-400" style={descriptionStyle}>
            Of tech talent is represented on our exclusive team of knowledgeable experts.
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default StatsBanner;
