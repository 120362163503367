/* eslint-disable no-nested-ternary */
/* eslint-disable new-cap */
/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
/* eslint-disable comma-dangle */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from "react";
import "../assets/css/ProjectPlanner.css";
import { jsPDF } from "jspdf";
import emailjs from "emailjs-com";
import validator from "validator";
import Footer from "parts/Footer";
import Header from "parts/Header";

export default class ProjectPlanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectCategory: "Software Development",
      projectType: "Website",
      numberOfPages: 1,
      contentAmount: 1,
      contentType: "Blog Posts",
      complexity: "Low",
      timeline: null,
      email: "",
      validEmail: true,
      formSubmitted: false,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  validateEmail = () => {
    const { email } = this.state;
    const isValid = validator.isEmail(email);
    this.setState({ validEmail: isValid });
    return isValid;
  };

  calculateTimeline = () => {
    const {
      projectCategory,
      projectType,
      numberOfPages,
      complexity,
      contentAmount,
      contentType,
    } = this.state;

    let totalWeeks = 0;

    if (projectCategory === "Software Development") {
      const baseWeeks = projectType === "Website" ? 4 : 6;
      const additionalTime =
        complexity === "High" ? 4 : complexity === "Medium" ? 2 : 0;
      const pagesTime = numberOfPages > 5 ? Math.ceil(numberOfPages / 5) : 1;

      totalWeeks = baseWeeks + additionalTime + pagesTime;
    } else if (projectCategory === "Content Creation") {
      const baseWeeks = contentType === "Videos" ? 3 : 2;
      const additionalTime =
        complexity === "High" ? 3 : complexity === "Medium" ? 2 : 0;
      const contentTime = contentAmount > 5 ? Math.ceil(contentAmount / 5) : 1;

      totalWeeks = baseWeeks + additionalTime + contentTime;
    }

    this.setState({
      timeline: {
        planning: 1,
        designOrCreation: Math.ceil(totalWeeks * 0.3),
        developmentOrCreation: Math.ceil(totalWeeks * 0.4),
        testingOrReview: Math.ceil(totalWeeks * 0.2),
        deploymentOrPublishing: 1,
        total: totalWeeks,
      },
    });
  };

  generatePDF = () => {
    const {
      projectCategory,
      timeline,
      projectType,
      numberOfPages,
      contentAmount,
      contentType,
    } = this.state;

    const doc = new jsPDF();
    const currentDate = new Date().toLocaleDateString();

    // Document Header
    doc.setFontSize(16);
    doc.text("Project Timeline Summary", 10, 10);

    doc.setFontSize(12);
    doc.text(`Prepared by: TTP Tech LLC`, 10, 20);
    doc.text(`Date: ${currentDate}`, 10, 30);

    // Project Details
    doc.text(`Project Category: ${projectCategory}`, 10, 40);

    if (
      projectCategory === "App Development" ||
      projectCategory === "Website Development"
    ) {
      doc.text(`Project Type: ${projectType}`, 10, 50);
      doc.text(`Number of Pages/Screens: ${numberOfPages}`, 10, 60);
    } else if (projectCategory === "Content Creation") {
      doc.text(`Content Type: ${contentType}`, 10, 50);
      doc.text(`Number of Content Pieces: ${contentAmount}`, 10, 60);
    }

    // Timeline Breakdown
    doc.text(`Estimated Total Timeline: ${timeline.total} Weeks`, 10, 70);
    doc.text(`Planning Phase: ${timeline.planning} week`, 10, 80);
    doc.text(
      `Design/Creation Phase: ${timeline.designOrCreation} weeks`,
      10,
      90
    );
    doc.text(
      `Development/Creation Phase: ${timeline.developmentOrCreation} weeks`,
      10,
      100
    );
    doc.text(
      `Testing/Review Phase: ${timeline.testingOrReview} weeks`,
      10,
      110
    );
    doc.text(
      `Deployment/Publishing Phase: ${timeline.deploymentOrPublishing} week`,
      10,
      120
    );

    // Suggestions based on input
    doc.setFontSize(14);
    doc.text("Recommendations:", 10, 130);
    doc.setFontSize(12);

    if (projectCategory === "Software Development" && projectType === "App") {
      doc.text(
        `• Consider adding user authentication and scalable cloud features.`,
        10,
        140
      );
      doc.text(
        `• Regular maintenance post-deployment is essential for app success.`,
        10,
        150
      );
    } else if (
      projectCategory === "Software Development" &&
      projectType === "Website"
    ) {
      doc.text(
        `• Optimize for SEO and mobile responsiveness for better performance.`,
        10,
        140
      );
      doc.text(
        `• Add an e-commerce or blog functionality based on your goals.`,
        10,
        150
      );
    } else if (projectCategory === "Content Creation") {
      doc.text(
        `• Ensure consistency in content release for better engagement.`,
        10,
        140
      );
      doc.text(
        `• Leverage social media channels for greater content distribution.`,
        10,
        150
      );
    }

    // Footer: Call to Action
    doc.setFontSize(12);
    doc.text(
      "Please reach out to us at TTP Tech LLC to bring your ideas to reality.",
      10,
      170
    );
    doc.text(
      "Contact us at info@ttptechllc.com for a more detailed project plan.",
      10,
      180
    );

    // Save the document
    doc.save(`project_timeline_ttptechllc_${currentDate}.pdf`);
  };

  sendNotification = () => {
    emailjs
      .send(
        "service_m9ez6le",
        "template_z0voz9g",
        {
          email: this.state.email, // Email of the user
          projectCategory: this.state.projectCategory, // Category (App, Website, Content Creation)
          projectType: this.state.projectType || "N/A", // App or Website type (if applicable)
          numberOfPages: this.state.numberOfPages || "N/A", // Number of pages/screens (if applicable)
          complexity: this.state.complexity, // Complexity level (Low, Medium, High)
          contentAmount: this.state.contentAmount || "N/A", // Number of content pieces (if applicable)
          contentType: this.state.contentType || "N/A", // Type of content (Videos, Blogs, etc.)
          totalTimeline: this.state.timeline
            ? this.state.timeline.total
            : "N/A", // Total project timeline
          planningPhase: this.state.timeline
            ? this.state.timeline.planning
            : "N/A", // Planning timeline
          designPhase: this.state.timeline
            ? this.state.timeline.designOrCreation
            : "N/A", // Design/Creation timeline
          developmentPhase: this.state.timeline
            ? this.state.timeline.developmentOrCreation
            : "N/A", // Development timeline
          testingPhase: this.state.timeline
            ? this.state.timeline.testingOrReview
            : "N/A", // Testing/Review timeline
          deploymentPhase: this.state.timeline
            ? this.state.timeline.deploymentOrPublishing
            : "N/A", // Deployment/Publishing timeline
        },
        "user_Jmss4bRHKILhJ0KTp7Zxf"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  handleSubmit = (e) => {
    e.preventDefault();

    // First, calculate the timeline before generating the PDF
    this.calculateTimeline();

    // Check if the timeline exists and is valid
    setTimeout(() => {
      // Adding a slight delay to ensure state is updated before proceeding
      if (this.state.timeline && this.state.timeline.total) {
        if (this.validateEmail()) {
          this.setState({ formSubmitted: true });
          this.generatePDF();
          this.sendNotification();
        }
      } else {
        alert(
          "Error: Please make sure all details are filled out properly and the timeline is generated."
        );
      }
    }, 100); // 100ms delay to ensure `setState` finishes
  };

  render() {
    const {
      projectCategory,
      projectType,
      numberOfPages,
      contentAmount,
      contentType,
      complexity,
      timeline,
      email,
      validEmail,
      formSubmitted,
    } = this.state;

    return (
      <>
        <Header {...this.props} />

        <div className="project-planner-container">
          <h1 className="text-6xl text-theme-blue font-bold leading-tight">
            Project Planner & Timeline Generator
          </h1>
          <p>
            Input your project details to estimate the development or content
            creation timeline.
          </p>

          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="projectCategory">Project Category:</label>
              <select
                name="projectCategory"
                value={projectCategory}
                onChange={this.handleChange}
              >
                <option value="Software Development">
                  Software Development
                </option>
                <option value="Content Creation">Content Creation</option>
              </select>
            </div>

            {projectCategory === "Software Development" && (
              <>
                <div className="form-group">
                  <label htmlFor="projectType">Project Type:</label>
                  <select
                    name="projectType"
                    value={projectType}
                    onChange={this.handleChange}
                  >
                    <option value="Website">Website</option>
                    <option value="App">App</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="numberOfPages">
                    Number of Pages/Screens:
                  </label>
                  <input
                    type="number"
                    name="numberOfPages"
                    value={numberOfPages}
                    onChange={this.handleChange}
                    min="1"
                  />
                </div>
              </>
            )}

            {projectCategory === "Content Creation" && (
              <>
                <div className="form-group">
                  <label htmlFor="contentType">Content Type:</label>
                  <select
                    name="contentType"
                    value={contentType}
                    onChange={this.handleChange}
                  >
                    <option value="Blog Posts">Blog Posts</option>
                    <option value="Videos">Videos</option>
                    <option value="Social Media Content">
                      Social Media Content
                    </option>
                    <option value="All">All of the Above</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="contentAmount">
                    Number of Content Pieces:
                  </label>
                  <input
                    type="number"
                    name="contentAmount"
                    value={contentAmount}
                    onChange={this.handleChange}
                    min="1"
                  />
                </div>
              </>
            )}

            <div className="form-group">
              <label htmlFor="complexity">Project Complexity:</label>
              <select
                name="complexity"
                value={complexity}
                onChange={this.handleChange}
              >
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="email">Email Address:</label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={this.handleChange}
              />
              {!validEmail && (
                <p className="error">Please enter a valid email address</p>
              )}
              <h6 style={{ fontSize: "12px" }}>
                By entering your email, you agree to receive marketing emails
                from TTP TECH LLC.
              </h6>
            </div>

            <button type="submit" className="calculate-btn transform transition duration-500 hover:scale-105">
              Generate Your Free Timeline and Download PDF
            </button>
          </form>

          {timeline && formSubmitted && (
            <div className="timeline-result">
              <h2>Estimated Timeline: {timeline.total} Weeks</h2>
              <p>
                <strong>Planning:</strong> {timeline.planning} week
              </p>
              <p>
                <strong>
                  {projectCategory === "Software Development"
                    ? "Design"
                    : "Creation"}
                  :
                </strong>{" "}
                {timeline.designOrCreation} weeks
              </p>
              <p>
                <strong>
                  {projectCategory === "Software Development"
                    ? "Development"
                    : "Creation"}
                  :
                </strong>{" "}
                {timeline.developmentOrCreation} weeks
              </p>
              <p>
                <strong>Testing/Review:</strong> {timeline.testingOrReview}{" "}
                weeks
              </p>
              <p>
                <strong>
                  {projectCategory === "Software Development"
                    ? "Deployment"
                    : "Publishing"}
                  :
                </strong>{" "}
                {timeline.deploymentOrPublishing} week
              </p>
            </div>
          )}
        </div>
        <Footer />
      </>
    );
  }
}
