/* eslint-disable react/jsx-filename-extension */
import React from "react";

import BrandIcon from "parts/BrandIcon";
import Button from "elements/Button";
import NewsletterForm from "pages/NewsletterFooter";

export default function Footer() {
  return (
    <div className=" bg-gray-50 border-t border-gray-200 pb-6">
      <div className="container flex-col mx-auto ">
        <div className="flex flex-col sm:flex-row mt-8 justify-center">
          <div className="w-1/3 flex-col ml-16 mr-8">
            <BrandIcon />
            {/* <p className="w-full text-lg text-gray-400 font-light">
              Growing Your Business
              {' '}
              <br />
              Is Our Calling
            </p> */}
          </div>
          <NewsletterForm />
          <div className="w-1/3 mt-0 ml-16 mr-0 sm:ml-0 sm:mr-5">
            {/* <h1 className="text-lg text-theme-blue pt-4 pb-2">
              Info
            </h1>
            <p className="text-lg text-gray-400 font-light">
              ttptechllc@gmail.com
            </p>
            <p className="text-lg text-gray-400 font-light">
              Blacksburg, VA
            </p> */}
          </div>
          <div className="w-1/3 ml-16 sm:ml-0 mt-0">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">Social</h1>
            <Button
              href="https://www.instagram.com/ttptech/"
              type="link"
              target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              Instagram
            </Button>
            <Button
              href="https://www.linkedin.com/company/ttp-tech/"
              type="link"
              target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              LinkedIn
            </Button>
          </div>
        </div>
        <div className="w-1/3 ml-16 sm:ml-0 mt-0">
          <h1 className="text-lg text-theme-blue pt-4 pb-2">Find us</h1>
          <Button
            href="https://www.google.com/maps/@36.8513649,-76.2897242,3a,75y,80.87h,89.37t/data=!3m6!1e1!3m4!1sR32kevvnwKFxEJ3tfUwl_Q!2e0!7i16384!8i8192?entry=ttu"
            type="link"
            target="_blank"
            className="flex text-lg text-gray-400 font-light hover:underline"
            isExternal
          >
            400 Granby Street, Norfolk VA 23510
          </Button>
        </div>
        <div className="flex-col text-center mt-7">
          <p className="text-lg text-gray-400 font-light">
            Copyright 2024 - All rights reserved - TTP TECH LLC
          </p>
          <div className="flex-row">
            {/* <p className="inline-block text-lg text-gray-400 font-light">
              Made with &#x2764; by&nbsp;
            </p> */}
            {/* <Button href="https://github.com/racmathafidz" type="link" target="_blank" className="text-lg text-theme-purple font-light" isExternal>
              Racmat Hafidz Fadli
            </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
