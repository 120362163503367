import React from "react";
import "./ToolCard.css"; // Importing CSS for the tool card styling

const ToolCard = ({ title, description, icon, link }) => (
  <a
    href={link}
    className="card rounded-2xl bg-dark-theme-purple py-8 sm:px-8 sm:py-8 xl:px-16 shadow-2xl discuss transform transition duration-500 hover:scale-105"
  >
    {" "}
    {/* No curly braces needed, directly return JSX */}
    <img src={icon} alt={`${title} icon`} className="icon" />
    <h3 className="card-title">{title}</h3>
    <p className="card-description">{description}</p>
    <button type="submit" className="use-tool-btn flex items-center justify-center">
      Use Tool
      <svg
        className="ml-1 w-5 h-5 animate-bounce-x"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 5l7 7-7 7"
        />
      </svg>
    </button>
  </a>
);

export default ToolCard;
