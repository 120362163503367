/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from "react";
import { Helmet } from "react-helmet"; // Add react-helmet for SEO meta tags
import Header from "parts/Header";
import Footer from "parts/Footer";
import StatsBanner from "parts/StatsBanner";
import FaqList from "parts/FaqList";
import ToolsPage from "./ToolsPage";

export default class ForEliteEntrepreneurs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        {/* SEO Meta Tags */}
        <Helmet>
          <title>Build and Grow Your Service Business | For Elite Entrepreneurs</title>
          <meta
            name="description"
            content="Build and grow your service-based business with tools and resources designed for elite entrepreneurs. Explore tools that help you succeed, including a profit margin calculator, project planner, and more."
          />
          <meta
            name="keywords"
            content="entrepreneurs, build a service business, grow your business, profit margin calculator, project planner, service business tools"
          />
          <meta name="author" content="TTP Tech LLC" />
          <link rel="canonical" href="https://ttptechllc.com/tools" />
          <meta property="og:title" content="Build and Grow Your Service Business" />
          <meta property="og:description" content="Explore essential tools and resources to build and grow your service-based business." />
          <meta property="og:image" content="https://ttptechllc.com/assets/images/Collaborative.png" />
          <meta property="og:url" content="https://ttptechllc.com/tools" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

        <Header {...this.props} />
        <div>
          <h1 className="text-6xl text-theme-blue font-bold leading-tight">
            Build and Grow Your Service Business
          </h1>
          <p className="subheader">
            Explore tools and resources designed to help elite entrepreneurs build and grow their service-based businesses.
          </p>
          <ToolsPage />
        </div>
        <StatsBanner />
        <FaqList />
        {/* Image with Alt Text for SEO */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="../assets/images/Collaborative.png"
            alt="Build and Grow Your Service Business"
          />
        </div>
        <Footer />
      </>
    );
  }
}
