/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from "react";

import Header from "parts/Header";
import HeroTeam from "parts/HeroTeam";
import AllTeam from "parts/AllTeam";
import Footer from "parts/Footer";

import Data from "json/landingPage.json";
import StatsBanner from "parts/StatsBanner";

export default class TeamPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Header {...this.props} />
        <AllTeam data={Data.team} />
        <HeroTeam {...this.props} />
        <StatsBanner />
        <div className=" bg-theme-purple flex flex-row items-start justify-between px-4 lg:px-0 ">
          <h1 className="font-light text-l text-white leading-relaxed mt-20 ml-2">
            What we offer:
          </h1>
          <div className="flex flex-col justify-start space-y-4 pl-4 pr-4 lg:pl-8 lg:pr-8 lg:pb-12">
            <p className="font-light text-l text-white leading-relaxed mt-20">
              Our company specializes in delivering cutting-edge digital
              solutions tailored to meet diverse business needs. From crafting
              seamless mobile experiences with Bluetooth-enabled and React
              Native apps, to developing robust property management portals, we
              ensure your operations are streamlined and efficient.
            </p>
            <p className="font-light text-l text-white leading-relaxed">
              Our expertise extends to creating visually appealing and highly
              functional WordPress sites and custom React.js websites, designed
              to elevate your online presence. For businesses targeting the iOS
              market, we offer native app development, ensuring a smooth and
              intuitive user experience that leverages the full potential of
              Apple's ecosystem.
            </p>
            <p className="font-light text-l text-white leading-relaxed">
              E-commerce ventures can benefit from our Shopify store development
              services, designed to create engaging shopping experiences that
              drive sales. Beyond development, we provide ongoing support
              through monthly updates, ensuring your digital assets remain
              up-to-date and secure.
            </p>
            <p className="font-light text-l text-white leading-relaxed">
              Our creative team also designs compelling flyers and executes
              targeted email lead generation campaigns to enhance your marketing
              efforts. Additionally, we offer domain management services to
              establish your brand's online identity.
            </p>
            <p className="font-light text-l text-white leading-relaxed">
              Expanding your reach, we specialize in crafting and managing FB
              Ads and Google Ads campaigns, driving traffic and conversions
              through strategic online advertising. Let us help you navigate the
              digital landscape with our comprehensive suite of services,
              designed to propel your business forward.
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src="../assets/images/Collaborative.png" alt="about_ttp_tech" />
        </div>

        <Footer />
      </>
    );
  }
}
