/* eslint-disable react/jsx-filename-extension */
import React from "react";

import { Fade } from "react-awesome-reveal";
import Button from "elements/Button";

import BuildWebsite from "assets/images/Design.png";
// w-full lg:w-1/2 xl:pl-12 sm:pr-2 mt-8

export default function Hero() {
  return (
    <section className="hero">
      <Fade bottom>
        <div className="">
          <h1 className="text-5xl sm:text-6xl text-theme-blue font-bold leading-tight mb-5">
            Trust The Process <br />
          </h1>

          <p className="font-light text-xl text-gray-400 leading-relaxed mb-16">
            We help elite entrepreneurs solve their toughest digital and software
            challenges, so they can focus on scaling their vision and growing
            faster.
          </p>
          <span className="flex justify-between text-xl sm:text-1xl text-theme-blue font-bold mb-4">
            <Button
              href="/projects"
              type="link"
              className="flex items-center px-6 py-3 text-white bg-theme-purple rounded-lg hover:bg-dark-theme-purple transform transition duration-500 hover:scale-105"
            >
              See Our Work
              <svg
                className="ml-1 w-5 h-5 animate-bounce-x"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </Button>
            <Button
              href="/tools"
              type="link"
              className="flex items-center px-6 py-3 text-white bg-theme-purple rounded-lg hover:bg-dark-theme-purple transition transform transition duration-500 hover:scale-105"
            >
              Tools for Entrepreneurs

              <svg
                className="ml-1 w-5 h-5 animate-bounce-x"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </Button>
          </span>
        </div>
      </Fade>

      <Fade bottom>
        <div className="flex pt-5 w-full justify-center items-center order-first md:w-full lg:order-last lg:w-1/2">
          <img className="" src={BuildWebsite} alt="Custom Websitess" />
        </div>
      </Fade>
    </section>
  );
}
