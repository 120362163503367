/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { Helmet } from "react-helmet"; // Import Helmet for managing head
import "./ToolsPage.css";
import profitCalculatorIcon from "assets/images/team.png";
import logoMakerIcon from "assets/images/screely.png";
import ebookIcon from "assets/images/Web.png";
import invoiceIcon from "assets/images/Communicative.png";

import ToolCard from "./ToolsCard";

export default class ToolsPage extends Component {
  render() {
    return (
      <div className="container">
        {/* SEO Meta Tags */}
        <Helmet>
          <title>Business Tools to Boost Growth | TTP Tech LLC</title>
          <meta
            name="description"
            content="Explore essential tools for business growth, including a profit margin calculator, project planner, and our guide for building a successful service-based business."
          />
          <meta
            name="keywords"
            content="business tools, invoice generator, profit margin calculator, project planner, service-based business guide, build a business, SEO tools"
          />
          <meta name="author" content="TTP Tech LLC" />
          <link rel="canonical" href="https://ttptechllc.com/tools" />
        </Helmet>

        {/* Page Content */}
        <h1 className="header">Tools to Boost Your Business</h1>
        <p className="subheader">
          Explore tools designed to help grow your business.
        </p>
        <div className="gridForTools">
          <ToolCard
            title="How To Start Your Service-Based Business ⭐️⭐️⭐️⭐️⭐️"
            description="Get our essential guide to building a successful service-based business."
            icon={ebookIcon}
            link="https://tahjerelewis.gumroad.com/l/ttptech"
          />
          <ToolCard
            title="Create Your Invoices ⭐️⭐️⭐️⭐️⭐️"
            description="Generate beautiful invoices with ease. Download and send them to your clients instantly."
            icon={invoiceIcon} // Make sure to replace this with the appropriate icon for the invoice generator
            link="/invoice-generator" // The link to your Invoice Generator page
          />
          <ToolCard
            title="Profit Margin Calculator ⭐️⭐️⭐️⭐️⭐️"
            description="Calculate profit margins for your products or services."
            icon={profitCalculatorIcon}
            link="/profit-margin-calculator"
          />
          <ToolCard
            title="Project Planner ⭐️⭐️⭐️⭐️⭐️"
            description="Create your project planner for your website, app, or content creation."
            icon={logoMakerIcon}
            link="/project-planner"
          />
          {/* Add more ToolCard components for other tools */}
        </div>
      </div>
    );
  }
}
