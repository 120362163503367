/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from "react";
import "../assets/css/ProfitMarginPage.css";
import Header from "parts/Header";
import Footer from "parts/Footer";
import emailjs from "emailjs-com";
import { Helmet } from "react-helmet"; // Import Helmet for SEO

export default class ProfitMarginPage extends Component {
  constructor(props) {
    super(props);
    // Initialize state in the constructor
    this.state = {
      costPrice: "",
      sellingPrice: "",
      profitMargin: null,
      email: "", // Added email to the state
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // Method to handle input changes
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value, // Keep values as string for input fields
    });
  };

  // Method to calculate profit margin
  calculateProfitMargin = (event) => {
    event.preventDefault(); // Prevent form submission reload
    const { costPrice, sellingPrice, email } = this.state;

    if (!this.validateEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (costPrice && sellingPrice < costPrice) {
      const margin = ((sellingPrice - costPrice) / sellingPrice) * 100;
      this.setState({ profitMargin: margin.toFixed(2) });

      // After calculating the margin, send data to EmailJS
      this.sendEmail();
    } else {
      this.setState({ profitMargin: null });
      alert("Please make sure the selling price is greater than the cost price.");
    }
  };

  // Method to validate email address
  validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  // Method to send email with EmailJS
  sendEmail = () => {
    const { costPrice, sellingPrice, profitMargin, email } = this.state;

    emailjs
      .send(
        "service_m9ez6le", // Your EmailJS service ID
        "template_z0voz9g", // Your EmailJS template ID
        {
          cost_price: costPrice,
          selling_price: sellingPrice,
          profit_margin: profitMargin,
          email, // Send user's email
        },
        "user_Jmss4bRHKILhJ0KTp7Zxf", // Your EmailJS public key/user ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Profit margin details have been sent to your email.");
        },
        (error) => {
          console.log("FAILED...", error);
          // alert("There was an error sending the email. Please try again.");
        },
      );
  };

  render() {
    const { costPrice, sellingPrice, profitMargin, email } = this.state;

    return (
      <>
        {/* SEO Meta Tags */}
        <Helmet>
          <title>Profit Margin Calculator | TTP Tech</title>
          <meta
            name="description"
            content="Easily calculate profit margins for your products or services using our free online profit margin calculator. Enter your cost price and selling price to get started."
          />
          <meta
            name="keywords"
            content="profit margin calculator, business tools, calculate profit, margin calculator, cost price, selling price"
          />
          <meta name="author" content="TTP Tech LLC" />
          <link rel="canonical" href="https://ttptechllc.com/profit-margin-calculator" />
          <meta property="og:title" content="Profit Margin Calculator | TTP Tech" />
          <meta property="og:description" content="Use TTP Tech's free online profit margin calculator to determine the profit margins for your products or services." />
          <meta property="og:url" content="https://ttptechllc.com/profit-margin-calculator" />
          <meta property="og:image" content="https://ttptechllc.com/assets/images/Design.png" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

        <Header {...this.props} />
        <div className="profit-margin-container">
          <h1 className="text-6xl text-theme-blue font-bold leading-tight">Profit Margin Calculator</h1>
          <p>
            Use our profit margin calculator to easily calculate the profit
            margin of your product.
          </p>

          <form
            onSubmit={this.calculateProfitMargin}
            className="profit-margin-form"
          >
            <div className="form-group">
              <label htmlFor="cost-price">Cost Price ($)</label>
              <input
                type="number"
                id="cost-price"
                name="costPrice"
                value={costPrice}
                onChange={this.handleChange}
                placeholder="Enter cost price"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="selling-price">Selling Price ($)</label>
              <input
                type="number"
                id="selling-price"
                name="sellingPrice"
                value={sellingPrice}
                onChange={this.handleChange}
                placeholder="Enter selling price"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={this.handleChange}
                placeholder="Enter your email"
                required
              />
              <h6 style={{ fontSize: "12px" }}>By entering your email, you agree to receive marketing emails from TTP TECH LLC.</h6>
            </div>

            <button type="submit" className="calculate-btn transform transition duration-500 hover:scale-105">
              Calculate Profit Margin
            </button>
          </form>

          {profitMargin !== null && (
            <div className="result">
              <h2>Profit Margin: {profitMargin}%</h2>
            </div>
          )}
        </div>
        <Footer />
      </>
    );
  }
}
