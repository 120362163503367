// /* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import './SoftwareCostCalculator.css';
import React, { Component } from "react";
import Header from "parts/Header";
import Footer from "parts/Footer";
import Data from "json/landingPage.json";
import PricingLayout from "parts/PricingLayout";
import Button from 'elements/Button';
import FaqList from 'parts/FaqList';
// eslint-disable-block

export default class Pricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFeatures: [],
      totalCost: 0,
    };

    this.features = [
      { name: "Custom Website", cost: 8000, description: "A tailor-made website design that aligns perfectly with your brand identity and business goals, featuring a responsive layout, intuitive navigation, and a professional aesthetic to captivate your audience." },
      { name: "User Authentication", cost: 2000, description: "Secure login functionality to protect user accounts, including registration, password recovery, and encryption to ensure user data is safe and access is controlled." },
      { name: "Email Integration Contact Us", cost: 2000, description: "A streamlined email setup within your site that enables visitors to contact you directly through an integrated form, enhancing user engagement and support." },
      { name: "Custom Domain Email", cost: 500, description: "Professional email addresses using your own domain, reinforcing your brand and providing a cohesive and credible communication channel with customers." },
      { name: "Logo Design", cost: 1000, description: "Creative and impactful logo creation to establish your brand's identity, making a lasting impression on your customers and standing out in the marketplace." },
      { name: "Cloud Database Integration", cost: 5000, description: "Scalable and secure cloud database solutions to manage your website's data with ease, ensuring data integrity and accessibility for your applications." },
      { name: "E-commerce", cost: 7500, description: "Comprehensive e-commerce integration to transform your website into a powerful sales platform, complete with product listings, shopping cart functionality, and user account management." },
      { name: "Payment Processing", cost: 4500, description: "Robust payment processing solutions to facilitate smooth and secure transactions, supporting multiple payment methods and enhancing customer trust." }];
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.calculateTotalCost = this.calculateTotalCost.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleCheckboxChange(event) {
    const featureName = event.target.name;
    const isChecked = event.target.checked;
    const { selectedFeatures } = this.state;

    if (isChecked) {
      this.setState({ selectedFeatures: [...selectedFeatures, featureName] }, () => {
        this.calculateTotalCost();
      });
    } else {
      this.setState({
        selectedFeatures: selectedFeatures.filter(
          (name) => name !== featureName,
        ),
      }, () => {
        this.calculateTotalCost();
      });
    }
  }

  calculateTotalCost() {
    const { selectedFeatures } = this.state;
    let cost = 0;
    selectedFeatures.forEach((feature) => {
      const selectedFeature = this.features.find((f) => f.name === feature);
      cost += selectedFeature.cost;
    });
    this.setState({ totalCost: cost });
  }
  render() {
    return (
      <>
        <Header {...this.props} />
        <section className="flex container mx-auto justify-center">
        <div className="flex flex-col w-10/12 sm:w-11/12 xl:w-10/12 rounded-2xl bg-theme-purple text-gray-100 py-14 sm:px-12 sm:py-20 xl:px-16 shadow-2xl discuss">

          <h1 className="w-2/3 text-5xl sm:text-2xl leading-tight font-semibold">Website Functionality Calculator</h1>
          <form>
            {this.features.map((feature) => (
              <div key={feature.name} className="flex container mx-auto justify-center mb-5 mt-2 checkbox">
                <input
                  type="checkbox"
                  id={feature.name}
                  name={feature.name}
                  value={feature.name}
                  onChange={this.handleCheckboxChange}
                />
                <label htmlFor={feature.name}>
                  {feature.name} (${feature.cost})
                  <span className="footnote">{feature.description}</span>
                </label>
              </div>
            ))}
          </form>
          <div>Total Cost: ${this.state.totalCost}</div>
          <div>Selected Features: {this.state.selectedFeatures.join(", ")}</div>
        </div>
        </section>
        <Button className="flex  justify-center mb-12 text-lg mx-auto ml-3 px-6 py-2 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200" type="link" href="/discuss-project">
              Contact Us
        </Button>
        <FaqList />
        <PricingLayout data={Data.pricing} />
        <Footer />
      </>
    );
  }
}
